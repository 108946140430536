import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import * as Sentry from '@sentry/react';

import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { firebaseConfig } from './firebase/firebaseConfig';
import { getEnvironmentFromEnvironment } from './lib/getEnvironmentFromEnvironment';
import { initializeApp as initializeFirebaseApp } from 'firebase/app';
import { queryClient } from './api/queryClient';
import routes from './routes';
import { type Event, type EventHint } from '@sentry/react';
import { ReactQueryError } from '@src/lib/basketNotificationHandling/ReactQueryError';

Sentry.init({
  dsn: 'https://5c0a263e52fd4fcbb512c3b76e422e11@o105411.ingest.us.sentry.io/4505045430829056',
  environment: getEnvironmentFromEnvironment(),
  debug: false, // set to true to see what's getting sent to Sentry
  ignoreErrors: [
    'auth/network-request-failed', // See SG-1435 - Ignore Firebase errors caused by a user's unstable connection.
  ],
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    // Disable replay integration for now
    // Sentry.replayIntegration(),
  ],
  tracesSampleRate: process.env?.SENTRY_RELEASE_VERSION === 'production' ? 1 : 0,
  ...(process.env?.SENTRY_RELEASE_VERSION || process.env?.GIT_BRANCH
    ? {
        release: process.env?.SENTRY_RELEASE_VERSION || process.env?.GIT_BRANCH,
      }
    : {}),
  beforeSend(event: Event, hint: EventHint): Event | null {
    const exception = hint.originalException;
    // Ignore ReactQueryError errors that are marked for Sentry ignore
    if (exception instanceof ReactQueryError && exception.__sentryIgnore) {
      return null; // Prevent this event from being sent to Sentry
    }

    // Commented out but useful to keep for debugging sentry events

    // if (event.exception && event.exception.values && event.exception.values.length > 0) {
    //   console.group('Sentry Error Event Debug Info');
    //
    //   const exception = event.exception?.values?.[0];
    //   if (exception) {
    //     console.log('Error Type:', exception.type);
    //     console.log('Error Message:', exception.value);
    //     console.log('Error Stack Trace:', exception.stacktrace);
    //   }
    //
    //   console.log('Error Tags:', event.tags);
    //   console.log('Error Extra Data:', event.extra);
    //   console.log('Error Breadcrumbs:', event.breadcrumbs);
    //
    //   // Use a type assertion here as JSON.stringify doesn't know about Event type specifics
    //   console.log('Full Event:', JSON.stringify(event as Record<string, unknown>, null, 2));
    //
    //   console.groupEnd();
    // }
    return event;
  },
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // Disable replay integration for now
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

function App() {
  initializeFirebaseApp(firebaseConfig);

  // useTracking();

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={sentryCreateBrowserRouter(routes)} />
    </QueryClientProvider>
  );
}

export default App;
