import { extractUnverifiedJwtPayload } from '@src/lib/extractUnverifiedJwtPayload/extractUnverifiedJwtPayload';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import customStorageDefinition from './persistent/customStorageDefinition';
import { InterfaceBPToken } from '@src/interfaces/tokens';
import { ObjectIsBPToken } from '@src/interfaces/typeguards/isBpToken';
import { setSentryTag } from '@src/lib/sentry/sentryUtils';

interface BpTokenState {
  bpToken: string | null;
  bpTokenExtractedUnverified: InterfaceBPToken | null;
  setBpToken: (newBpToken: string | null) => void;

  _hasBpTokenStoreHydrated: boolean;
  setHasHydrated: (hasHydrated: boolean) => void;
}

const useBpTokenStore = create<BpTokenState>()(
  persist(
    (set) => ({
      _hasBpTokenStoreHydrated: false,
      setHasHydrated: (hasHydrated) => {
        set({
          _hasBpTokenStoreHydrated: hasHydrated,
        });
      },

      bpToken: null,
      bpTokenExtractedUnverified: null,
      setBpToken: (newBpToken) => {
        const extractedToken = newBpToken ? extractUnverifiedJwtPayload(newBpToken) : null;
        const cauculatedExtractedUnverifiedToken =
          ObjectIsBPToken(extractedToken) || extractedToken === null ? extractedToken : null;

        // Set Sentry package_id tag if it exists within the token
        if (cauculatedExtractedUnverifiedToken?.p) {
          setSentryTag('package_id', cauculatedExtractedUnverifiedToken?.p);
        }

        set(() => ({
          bpToken: newBpToken,
          bpTokenExtractedUnverified: cauculatedExtractedUnverifiedToken,
        }));
      },
    }),

    {
      name: 'bp-token-persistent-storage',
      storage: createJSONStorage(() => customStorageDefinition),
      onRehydrateStorage: () => (state) => {
        state?.setHasHydrated(true);
      },
    }
  )
);

export default useBpTokenStore;
