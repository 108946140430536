import { ServiceMessageVariants } from '@kaboodle-solutions/design-library';

const isNoItemsAdded = (mutationPayload: unknown[], newBasket: unknown[], oldBasket: unknown[]) => {
  return mutationPayload.length > newBasket.length && newBasket.length === oldBasket.length;
};

const isPartialSuccess = (mutationPayload: unknown[], newBasket: unknown[], oldBasket: unknown[]) => {
  return mutationPayload.length > newBasket.length && newBasket.length > oldBasket.length;
};

const isSuccessWithMoreItemsAdded = (mutationPayload: unknown[], newBasket: unknown[], oldBasket: unknown[]) => {
  return newBasket.length > mutationPayload.length && newBasket.length > oldBasket.length;
};
const isCompleteSuccess = (mutationPayload: unknown[], newBasket: unknown[], oldBasket: unknown[]) => {
  return mutationPayload.length === newBasket.length && newBasket.length > oldBasket.length;
};
const isItemRemoved = (newBasket: unknown[], oldBasket: unknown[]) => {
  return oldBasket.length > newBasket.length;
};

type MutationSuccessToastType =
  | {
      status: 'NoItemsAdded' | 'PartialSuccess' | 'SuccessMoreItemsAdded' | 'CompleteSuccess' | 'ItemRemoved';
      testId: string;
      toastMessageTReference: 'completeSuccess' | 'noItemsAdded' | 'partialSuccess' | 'removedFromBasket';
      toastVariant: ServiceMessageVariants;
      autoClose: boolean;
    }
  | { status: 'Unhandled' };
export const getMutationSuccessStatus = (
  mutationPayload: unknown[],
  newBasket: unknown[],
  oldBasket: unknown[]
): MutationSuccessToastType => {
  if (isNoItemsAdded(mutationPayload, newBasket, oldBasket)) {
    return {
      status: 'NoItemsAdded',
      testId: 'no-items-added-toast',
      toastMessageTReference: 'noItemsAdded',
      toastVariant: ServiceMessageVariants.Error,
      autoClose: false,
    };
  }

  if (isPartialSuccess(mutationPayload, newBasket, oldBasket)) {
    return {
      status: 'PartialSuccess',
      testId: 'partial-success-toast',
      toastMessageTReference: 'partialSuccess',
      toastVariant: ServiceMessageVariants.Info,
      autoClose: false,
    };
  }

  if (isSuccessWithMoreItemsAdded(mutationPayload, newBasket, oldBasket)) {
    return {
      status: 'SuccessMoreItemsAdded',
      testId: 'success-added-more-items-toast',
      toastMessageTReference: 'completeSuccess',
      toastVariant: ServiceMessageVariants.Success,
      autoClose: true,
    };
  }

  if (isCompleteSuccess(mutationPayload, newBasket, oldBasket)) {
    return {
      status: 'CompleteSuccess',
      testId: 'complete-success-toast',
      toastMessageTReference: 'completeSuccess',
      toastVariant: ServiceMessageVariants.Success,
      autoClose: true,
    };
  }

  if (isItemRemoved(newBasket, oldBasket)) {
    return {
      status: 'ItemRemoved',
      testId: 'item-removed-from-basket',
      toastMessageTReference: 'removedFromBasket',
      toastVariant: ServiceMessageVariants.Success,
      autoClose: true,
    };
  }

  // Current gaps cannot be reached in current system
  // 1 - mutationPayload===newBasket===oldBasket===0 -> since the loop that calls this function checks if there is an engine key in the mutation, the array can't be empty.
  // 2 - mutationPayload===newBasket===oldBasket     -> since we can only add or remove items on each request, this condition can't be reached from the UI as os 13/01/2025.
  return {
    status: 'Unhandled',
  };
};
