import { parseISO, format } from 'date-fns';

/**
 * Formats a date string to 'dd-MM-yyyy'. Returns the original input if invalid.
 *
 * @param {string} date - The ISO date string to format.
 * @param {string} pattern - The patten to format the date into.
 * @returns {string} The formatted date or the original input if invalid.
 */
export default function formatDate(date: string, pattern = 'dd-MM-yyyy') {
  try {
    return format(parseISO(date), pattern);
  } catch {
    // Return original date if format fails
    return date;
  }
}
