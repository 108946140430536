import {
  BasketPaymentSelector,
  Button,
  type ButtonProps,
  Heading,
  Tooltip,
  Typography,
} from '@kaboodle-solutions/design-library';

import { BasketContent } from '@src/components/Basket';
import { BasketExpireTimer } from '@src/components/BasketExpireTimer';
import { DEVICE_TABLET_BREAKPOINT } from '@src/constants';
import { FaCircleXmark } from 'react-icons/fa6';
import { RestartBasketModalContent } from '../../ModalContents/RestartBasketModalContent';
import styles from './BasketCart.module.scss';
import { useBasketQuery } from '@src/api/useBasket';
import { useBasketStore } from '@src/store/useBasketStore';
import useCurrencyFormatter from '@src/hooks/useCurrencyFormatter/useCurrencyFormatter';
import { useEffect } from 'react';
import { useModalStore } from '@src/store/useModalStore';
import useScreenBreakpoint from '@src/hooks/useScreenBreakpoint';
import { useTranslation } from 'react-i18next';
import { useEngineNavigationStore } from '@src/store/useEngineNavigationStore';

export interface Props {
  toggleBasket: () => void;
  isExpanded: boolean;
  testId?: string;
  continueButtonProps: {
    id: string;
    disabled: boolean;
    testId: string;
    labelText: string;
    onClick: () => void;
    variant: ButtonProps['variant'];
  };
}

const BasketCart = ({ toggleBasket, isExpanded, testId = 'basketCart', continueButtonProps }: Props) => {
  const { screenWidth } = useScreenBreakpoint();
  const { format } = useCurrencyFormatter();
  const { t } = useTranslation();
  const { openModal } = useModalStore();
  const { data: basketData, hasEngineBasketItems } = useBasketQuery();
  const { shouldDisplayContinueButton } = useEngineNavigationStore();

  const { showMonthlyPrice, toggleMonthlyPrice, showBasket } = useBasketStore();

  const isOnMobile = screenWidth < DEVICE_TABLET_BREAKPOINT;

  const onHandleRestartBasket = () => {
    openModal(<RestartBasketModalContent />, true);
  };

  // Handle prevent scrolling when basket cart is open on mobile only.
  useEffect(() => {
    if (isOnMobile && isExpanded) {
      document.body.classList.toggle(styles.preventScroll);
    }

    return () => {
      document.body.classList.remove(styles.preventScroll);
    };
  }, [isOnMobile, isExpanded]);

  if (!showBasket) {
    return null;
  }

  const basketDataDiscounts = basketData?.data?.discounts;
  const protectionPlanActive = basketData?.data?.protectionPlan?.active;
  const protectionPlanTotal = protectionPlanActive ? basketData?.data?.protectionPlan?.price.value ?? 0 : 0;
  const basketDelivery = basketData?.data?.delivery;

  const basketTotal = basketData?.data?.calculated?.total.value ?? 0;
  const bookingFeeTotal = basketData?.data?.calculated?.total?.bookingFee ?? 0;

  return (
    <>
      {isOnMobile && (
        <div
          className={styles.basketBackdrop}
          data-testid={`${testId}-backdrop`}
          onClick={toggleBasket}
          role="button"
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              toggleBasket();
            }
          }}
        />
      )}
      <div
        className={`${styles.basketContentsContainer} ${isExpanded ? styles.basketCartOpen : styles.basketCartClosed}`}
      >
        <div className={styles.basketWrapper} data-testid={testId}>
          <div className={styles.basketHeaderWrapper}>
            <div className={styles.restartAndTimerContainer}>
              <button className={styles.restart} onClick={onHandleRestartBasket}>
                {t('basket:restart')}
              </button>
              <div className={styles.closeBasket}>
                <Typography tag="span" type="subtext" customClass={styles.closeBasketText}>
                  {t('basket:closebasket')}
                </Typography>
                <button
                  id="basket-cart-close-btn"
                  className={styles.basketCartBtnClose}
                  data-testid={`${testId}-closeBtn`}
                  onClick={toggleBasket}
                  tabIndex={0}
                  type="button"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      toggleBasket();
                    }
                  }}
                >
                  <FaCircleXmark />
                </button>
              </div>
            </div>
            <div className={styles.basket_header}>
              <Heading level="h4" isBold>
                {t('basket:title')}
              </Heading>
              <span className={styles.basketTimer_mobile}>
                <span> {t('basket:timerDesc')}</span>
                <BasketExpireTimer />
              </span>
            </div>
          </div>
          <hr />
          <BasketContent isBasketOpen={isExpanded} testId="basketCart-content" toggleBasket={toggleBasket} />
          {basketDelivery && (
            <>
              <hr />
              <div className={styles.basket_heading}>
                <Typography tag="span" isBold>
                  {t('basket:basketFees')}
                </Typography>
              </div>
              <div className={styles.basket_deliveryItemLine}>
                <Typography tag="span" customClass={styles.basket_deliveryItem_name}>
                  <Typography tag="span" isBold>
                    1x
                  </Typography>{' '}
                  {basketDelivery.optionName}
                </Typography>
                <Typography tag="span" isBold>
                  {format(basketDelivery?.price?.value)}
                </Typography>
              </div>
            </>
          )}
          {/* TODO: correct discount amount? */}
          {basketDataDiscounts && (
            <>
              <hr />
              <div className={styles.basket_discountContainer}>
                <Typography tag="span" isBold>
                  {t('basket:discounts')}
                </Typography>
                {basketDataDiscounts.map((discount, index) => (
                  <div key={`${discount.code}-${index}`} className={styles.basket_discountItem}>
                    <Typography tag="span" customClass={styles.basket_discountCode}>
                      {discount.code}
                    </Typography>
                    <Typography tag="span" customClass={styles.basket_discountPrice}>{`-${format(
                      discount.amount
                    )}`}</Typography>
                  </div>
                ))}
              </div>
            </>
          )}
          {protectionPlanActive && (
            <>
              <hr />
              <div className={styles.basket_singleLinePrice}>
                <Typography tag="span" isBold>
                  {t('basket:refundProtection')}
                </Typography>
                <Typography tag="span" isBold>
                  {format(protectionPlanTotal)}
                </Typography>
              </div>
            </>
          )}
          <hr />
          <div className={styles.basket_footer}>
            <div className={styles.viewBasketAndTotal}>
              <Typography tag="span">{t('common:words.totalPrice')}</Typography>
              <Typography tag="span" isBold>
                {format(basketTotal, true)}
              </Typography>
            </div>
            {bookingFeeTotal > 0 && (
              <div className={styles.bookingFeeTotal}>
                <Typography tag="span" customClass={styles.bookingFeeTotalText}>
                  {t('basket:incl')} {t('basket:bookingFees')} {t('common:words.of')} {format(bookingFeeTotal, true)}
                  <Tooltip tipDesc={t('basket:bookingFeeTooltip')} />
                </Typography>
              </div>
            )}
          </div>
        </div>
        {/* Desktop Continue Button Footer */}
        {!isOnMobile && (
          <div className={`${styles.basketSubFooter}`}>
            <BasketPaymentSelector
              basketValue={
                showMonthlyPrice
                  ? `${format(basketData?.data?.calculated?.total?.monthlyPrice ?? 0, true)}`
                  : format(basketData?.data?.calculated?.total?.value ?? 0, true)
              }
              showPaymentSwitch={!!basketData?.data?.calculated?.total?.monthlyPrice}
              isPrepayment={showMonthlyPrice}
              onToggleBasket={toggleBasket}
              onToggleSwitch={toggleMonthlyPrice}
              labelPrePayment={t('basket:prePaymentPlanlabel')}
              labelPrePaymentAbbrv={t('basket:prePaymentPerMonthAbbrv')}
              labelTotal={t('common:words.total')}
              fullWidth={!isOnMobile}
              disabled={!hasEngineBasketItems}
              testId="basket-section-view-basket-selector"
            />
            {shouldDisplayContinueButton && <Button {...continueButtonProps} wide={!isOnMobile} />}
          </div>
        )}
      </div>
    </>
  );
};

export default BasketCart;
