import { createAndAddCssLink, transformClientIdToClientName } from './useSelectClientTheme.helper';
import { useEffect, useRef, useState } from 'react';

import useBpTokenStore from '@src/store/useBpTokenStore';
import { useSearchParams } from 'react-router-dom';
import { useStripeReturnUrlStore } from '@src/store/persistent/useStripeReturnUrlStore';
import { getCdnUrlFromEnvironment } from '@src/lib/getCdnUrlFromEnvironment';

export const useSelectClientTheme = () => {
  const [currentClientTheme, setCurrentClientTheme] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const rootElement = useRef(document.querySelector('body'));
  const { c: clientIDFromStripeReturnUrl } = useStripeReturnUrlStore();
  const cdnBaseUrl = getCdnUrlFromEnvironment();

  const { bpTokenExtractedUnverified } = useBpTokenStore();
  const clientIdFromParams = searchParams.get('c');

  useEffect(() => {
    const clientId = clientIdFromParams || clientIDFromStripeReturnUrl || bpTokenExtractedUnverified?.c;
    const { theme, prefix } = transformClientIdToClientName(clientId);

    if (theme !== currentClientTheme) {
      setCurrentClientTheme(theme);

      if (prefix) {
        // Font CSS still uses the existing CDN for now
        createAndAddCssLink('font', theme, `https://dye1vg6h1p5m0.cloudfront.net/${prefix}/fonts/font-face.css`);

        // Theme CSS uses the new environment-specific CDN
        createAndAddCssLink('theme', theme, `${cdnBaseUrl}/BP/${clientId}/theme.css`);
      }
    }
  }, [bpTokenExtractedUnverified?.c, currentClientTheme, clientIdFromParams, clientIDFromStripeReturnUrl, cdnBaseUrl]);

  useEffect(() => {
    if (!rootElement.current?.classList.contains('currentClientTheme') && currentClientTheme) {
      rootElement.current?.setAttribute('class', currentClientTheme);
    }
  }, [currentClientTheme]);
};
