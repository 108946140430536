import { BasketSection } from '@src/components/Basket';
import { ENGINE_NAV_DATA } from '@src/constants';
import { NavigationStepper } from '@src/components/NavigationStepper';
import { Outlet } from 'react-router-dom';
import PaymentSidebar from '../PaymentSidebar/PaymentSidebar';
import { ToastNotificationWrapper } from '../ToastNotification';
import classNames from 'classnames';
import styles from './engineContainer.module.scss';
import useLayoutConfiguration from '@src/hooks/useLayoutConfiguration';
import { useNavigationController } from '@src/hooks/NavigationHooks/useNavigationController';

const EngineContainer = () => {
  const { cols, currentEnginePath, showNavStepper, showSideBar, engineContainerShouldGrow } = useLayoutConfiguration();

  const actualShowSideBar = cols !== 1 && showSideBar;

  useNavigationController();

  return (
    <>
      {showNavStepper ? <NavigationStepper /> : null}
      <section
        className={classNames(styles.engineContainer, {
          [styles.growToFullHeight]: engineContainerShouldGrow,
        })}
      >
        <div className="toastManager">
          <ToastNotificationWrapper />
        </div>
        <Outlet />
        {actualShowSideBar && <Sidebar currentEnginePath={currentEnginePath} />}
      </section>
    </>
  );
};

function Sidebar({ currentEnginePath }: { currentEnginePath?: string }) {
  return currentEnginePath === ENGINE_NAV_DATA.PAYMENT.route ? <PaymentSidebar /> : <BasketSection />;
}

export default EngineContainer;
