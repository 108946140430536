import { RemoveButton } from './RemoveButton';
import styles from './basketContent.module.scss';

interface BasketItemWrapperProps {
  customClass?: string;
  children: React.ReactNode;
  showRemoveButton: boolean;
  isDisabledRemoveButton: boolean;
  isRemoving: boolean;
  onRemove: () => void;
}

export const BasketItemWrapper = ({
  customClass = '',
  children,
  showRemoveButton = false,
  isDisabledRemoveButton,
  isRemoving,
  onRemove,
}: BasketItemWrapperProps) => (
  <div className={`${styles.basketItem__wrapper} ${customClass}`}>
    <div className={styles.basketItem_rowWrapper}>
      <div className={styles.basketItem}>{children}</div>
      {showRemoveButton && (
        <RemoveButton disabled={isDisabledRemoveButton} isRemoving={isRemoving} onRemove={onRemove} />
      )}
    </div>
  </div>
);
