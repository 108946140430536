import { useMemo } from 'react';
import { useQuery, UseQueryOptions, QueryObserverResult } from '@tanstack/react-query';
import { useErrorBoundary } from '@components/ErrorBoundary';
import { HTTP_STATUS } from '@src/constants';
import { handleApiError } from '@src/lib/handleApiError';
import * as axios from 'axios';

import { getTransportTypes, getTransportDates, getTransportLocations, getTransportStops } from '@src/api/transport'; // Assuming you've placed these functions in this file

import {
  TransportType,
  KapiPackageTransportDate,
  KapiPackageTransportLocation,
  PackageTransportStop,
  TransportTypeOption,
  TransportDirectionOption,
  TransportDate,
} from '@src/interfaces/transport';
import { useTransportStore } from '@store/useTransportStore';
import useSelectedEngineFilterStore from '@src/store/persistent/selectedEngineFilter/useSelectedEngineFilterStore';

const useQueryResult = <T>(
  query: QueryObserverResult<T, Error>
): {
  isError: boolean;
  data: T | undefined;
  error: Error | null;
  isPending: boolean;
  isSuccess: boolean;
  isLoading: boolean;
} => {
  const { data, error, isError, isSuccess, isPending, isLoading } = query;
  return { data, error, isError, isSuccess, isPending, isLoading };
};

const useApiQuery = <T>(
  queryKey: string[],
  queryFn: () => Promise<axios.AxiosResponse<T>>,
  options?: UseQueryOptions<T, Error, T>
): QueryObserverResult<T, Error> => {
  const { showBoundary } = useErrorBoundary();

  const query = useQuery<T, Error, T>({
    queryKey,
    queryFn: async () => {
      const response = await queryFn();
      return response.data;
    },
    retry: (failureCount, error) => {
      if (axios.isAxiosError(error) && error.response?.status === HTTP_STATUS.InternalServerError) {
        return false;
      }
      return failureCount <= 2;
    },
    ...options,
  });

  handleApiError({ error: query.error, showBoundary });

  return query;
};

export const useTransportQueries = () => {
  const {
    travelDirection,
    departDate,
    departLocation,
    arrivalLocation,
    setTravelDirection,
    setDepartDate,
    setDepartLocation,
    setArrivalLocation,
    setSelectedOutStop,
    setSelectedReturnStop,
    setCustomers,
  } = useTransportStore();
  const { transportType: selectedTransportTypeHandle, setSelectedEngineFilterStore } = useSelectedEngineFilterStore();

  const transportTypesQueryKey = ['transportTypes'];
  const transportTypesQuery = useApiQuery(transportTypesQueryKey, getTransportTypes, {
    queryKey: transportTypesQueryKey,
  });

  const transportDatesQueryKey = ['transportDates', selectedTransportTypeHandle ?? '', travelDirection ?? ''];
  const transportDatesQuery = useApiQuery(
    transportDatesQueryKey,
    () =>
      getTransportDates(
        selectedTransportTypeHandle as TransportTypeOption,
        travelDirection === TransportDirectionOption.BOTH
          ? TransportDirectionOption.OUTBOUND
          : (travelDirection as TransportDirectionOption)
      ),
    {
      queryKey: transportDatesQueryKey,
      enabled: !!selectedTransportTypeHandle && !!travelDirection,
    }
  );

  const transportLocationsQueryKey = [
    'transportLocations',
    selectedTransportTypeHandle ?? '',
    travelDirection ?? '',
    departDate ?? '',
  ];
  const transportLocationsQuery = useApiQuery(
    transportLocationsQueryKey,
    () =>
      getTransportLocations(
        selectedTransportTypeHandle as TransportTypeOption,
        travelDirection === TransportDirectionOption.BOTH
          ? TransportDirectionOption.OUTBOUND_WITH_RETURN
          : (travelDirection as TransportDirectionOption),
        departDate as TransportDate
      ),
    {
      queryKey: transportLocationsQueryKey,
      enabled: !!selectedTransportTypeHandle && !!travelDirection && !!departDate,
    }
  );

  const transportStopsQueryKey = [
    'transportStops',
    selectedTransportTypeHandle ?? '',
    travelDirection ?? '',
    departDate ?? '',
    departLocation?.toString() ?? '',
    arrivalLocation?.toString() ?? '',
  ];

  const transportStopsQuery = useApiQuery(
    transportStopsQueryKey,
    () =>
      getTransportStops(
        selectedTransportTypeHandle as TransportTypeOption,
        travelDirection as TransportDirectionOption,
        departDate as TransportDate,
        departLocation as number,
        arrivalLocation as number
      ),
    {
      queryKey: transportStopsQueryKey,
      enabled:
        !!selectedTransportTypeHandle && !!travelDirection && !!departDate && !!departLocation && !!arrivalLocation,
    }
  );

  const fetchTransportTypes = useQueryResult<TransportType[]>(transportTypesQuery);
  const fetchTransportDates = useQueryResult<KapiPackageTransportDate[]>(transportDatesQuery);
  const fetchTransportLocations = useQueryResult<KapiPackageTransportLocation[]>(transportLocationsQuery);
  const fetchTransportStops = useQueryResult<PackageTransportStop[]>(transportStopsQuery);

  const callbacks = useMemo(
    () => ({
      setTransportType: (type: TransportTypeOption) => {
        setSelectedEngineFilterStore('transportType', type);
        setTravelDirection(TransportDirectionOption.BOTH);
        setDepartDate(null);
        setDepartLocation(null);
        setArrivalLocation(null);
        setSelectedOutStop(null);
        setSelectedReturnStop(null);
        setCustomers(1);
      },
      setTransportDirection: (direction: TransportDirectionOption) => {
        setTravelDirection(direction);
        setDepartDate(null);
        setDepartLocation(null);
        setArrivalLocation(null);
        setSelectedOutStop(null);
        setSelectedReturnStop(null);
        setCustomers(1);
      },
      setTransportDate: (date: TransportDate | null) => {
        setDepartDate(date);
        if (date !== departDate) {
          setDepartLocation(null);
          setArrivalLocation(null);
          setSelectedOutStop(null);
          setSelectedReturnStop(null);
          setCustomers(1);
        }
      },
      setDepartureLocation: (location: number) => {
        setDepartLocation(location);
        setArrivalLocation(null);
        setSelectedOutStop(null);
        setSelectedReturnStop(null);
        setCustomers(1);
      },
      setArrivalLocation: (location: number) => {
        setArrivalLocation(location);
        setSelectedOutStop(null);
        setSelectedReturnStop(null);
        setCustomers(1);
      },
    }),
    [
      setSelectedEngineFilterStore,
      setTravelDirection,
      setDepartDate,
      setDepartLocation,
      setArrivalLocation,
      setSelectedOutStop,
      setSelectedReturnStop,
      setCustomers,
      departDate,
    ]
  );

  return {
    ...callbacks,
    fetchTransportTypes,
    fetchTransportDates,
    fetchTransportLocations,
    fetchTransportStops,
    setArrivalLocation,
    selectedTransportTypeHandle,
  };
};
