import { BasketPaymentSelector, Button } from '@kaboodle-solutions/design-library';
import { DEVICE_TABLET_BREAKPOINT, ENGINE_NAV_DATA } from '@src/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { BasketCart } from '@src/components';
import { FaShoppingBasket } from 'react-icons/fa';
import classNames from 'classnames';
import displayBasketErrors from '@src/lib/basketNotificationHandling/displayBasketErrors';
import { isBasketEmpty } from '@src/lib/isBasketEmpty';
import styles from './BasketSection.module.scss';
import tracking from '@src/lib/tracking';
import { useBasketQuery } from '@src/api/useBasket';
import { useBasketStore } from '@src/store/useBasketStore';
import useCurrencyFormatter from '@src/hooks/useCurrencyFormatter/useCurrencyFormatter';
import { useEngineNavigationStore } from '@src/store/useEngineNavigationStore';
import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { useLocation } from 'react-router-dom';
import useScreenBreakpoint from '@src/hooks/useScreenBreakpoint';
import { useTranslation } from 'react-i18next';

// Used in conjunction with css slide-out animation
const cartDisplayTimeout = 400;

const BasketSection = () => {
  const { options } = useInitResponseStore(({ options }) => ({
    options,
  }));

  const { t } = useTranslation(['common', 'basket']);
  const { format } = useCurrencyFormatter();
  const { data: basketData, isLoading: isBasketLoading, hasEngineBasketItems } = useBasketQuery();
  const location = useLocation();

  const {
    continueButtonVariant,
    getBasketRequestState,
    isTicketStateDirty,
    mutateBasketStatus,
    showBasket,
    setBasketStore,
    showCompleteBookingLabel,
    showMonthlyPrice,
    toggleMonthlyPrice,
  } = useBasketStore();
  const { continueButtonCallback, forceEnabledContinueButton, currentStep, nextStep, shouldDisplayContinueButton } =
    useEngineNavigationStore();
  const currentEngine = currentStep?.engineHandle;

  const [isExpanded, setIsExpanded] = useState<boolean>(showBasket);
  const { screenWidth } = useScreenBreakpoint();
  const isOnMobile = screenWidth < DEVICE_TABLET_BREAKPOINT;
  const showOnDesktop = !isOnMobile && !showBasket;

  useEffect(() => {
    setBasketStore({ showMonthlyPrice: options?.DISPLAY_PRICE_PRIORITY === 3 });
  }, [options?.DISPLAY_PRICE_PRIORITY, setBasketStore]);

  const setShowBasket = useCallback(
    (value: boolean) => {
      setBasketStore({
        showBasket: value,
      });
    },
    [setBasketStore]
  );

  const handleAddToBasket = useCallback(() => {
    getBasketRequestState ? getBasketRequestState() : undefined;
  }, [getBasketRequestState]);

  const toggleBasket = () => {
    setIsExpanded(!isExpanded);
  };

  // Based on `isExpanded`, if false and on mobile allow the CSS transiton to happen before
  // updating basket store state
  useEffect(() => {
    if (!isExpanded) {
      setTimeout(
        () => {
          setShowBasket(false);
        },
        isOnMobile ? cartDisplayTimeout : 0
      );
    } else {
      setShowBasket(true);
    }
  }, [isExpanded, setShowBasket, isOnMobile]);

  // Ensure the local `isExpanded` state is always in sync with
  // Basket Store's `showBasket`.
  useEffect(() => {
    setIsExpanded(showBasket);
  }, [showBasket, setIsExpanded]);

  const shouldDisplayDisabledButtonVariant = useCallback(() => {
    if (nextStep?.engineHandle === 'LOGIN') {
      if (!basketData || !basketData.data) return true;
      if (isBasketEmpty(basketData.data)) return true;
    }

    if (forceEnabledContinueButton || location.pathname === ENGINE_NAV_DATA['LOGIN'].route) {
      return Boolean(!forceEnabledContinueButton || mutateBasketStatus === 'pending');
    }

    return !nextStep?.enableNavigation || mutateBasketStatus === 'pending';
  }, [location, forceEnabledContinueButton, nextStep, mutateBasketStatus, basketData]);

  const handleContinue = useCallback(() => {
    // Always check for basket errors on continue click to refresh toasts
    if (basketData) {
      displayBasketErrors({
        basket: basketData?.data,
        engine: currentEngine,
        trigger: 'continue',
      });
    }

    if (!shouldDisplayDisabledButtonVariant()) {
      if (continueButtonCallback) continueButtonCallback();
      setIsExpanded(false);
      tracking.sendEvent({ event: 'basket_continue', engine: currentEngine || '', route: location.pathname });
    }
  }, [
    continueButtonCallback,
    setIsExpanded,
    currentEngine,
    location.pathname,
    basketData,
    shouldDisplayDisabledButtonVariant,
  ]);

  useEffect(() => {
    if (!hasEngineBasketItems) {
      setShowBasket(false);
    }
  }, [hasEngineBasketItems, setShowBasket]);

  const addToBasketLabel = useMemo(() => {
    if (mutateBasketStatus === 'pending') {
      return { label: t('common:loading'), testId: 'loading' };
    }

    return { label: t('common:addToBasket'), testId: 'add-to-basket' };
  }, [t, mutateBasketStatus]);

  const basketLoaded = !isBasketLoading;

  if (!basketLoaded) {
    return null;
  }

  const continueButtonProps = {
    id: 'basket-section-continue-btn',
    disabled: shouldDisplayDisabledButtonVariant(),
    testId: 'basket-section-continue-btn',
    labelText: showCompleteBookingLabel ? t('common:completeBooking') : t('common:continue'),
    onClick: handleContinue,
    variant: shouldDisplayDisabledButtonVariant()
      ? 'disabled'
      : isTicketStateDirty
      ? 'secondary'
      : continueButtonVariant,
  };

  return (
    <div className={classNames(styles.basketSection, { [styles.fixed]: isOnMobile && showBasket })}>
      {/* Expanded Cart */}
      {showBasket && (
        <BasketCart continueButtonProps={continueButtonProps} isExpanded={isExpanded} toggleBasket={toggleBasket} />
      )}
      {/* Collapsed Basket/Empty Basket (Desktop) || Basket/Empty Basket Footer Mobile */}
      {(showOnDesktop || isOnMobile) && (
        <div
          className={classNames(styles.collapsedBasketContainer, {
            [styles.collapsedBasketContainer__overlay]: showBasket,
          })}
        >
          {/* Add to basket button section */}
          <div
            className={classNames(
              {
                [styles.showUpdateCollapsedBasketContainer]: isTicketStateDirty,
                [styles.hideUpdateCollapsedBasketContainer]: !isTicketStateDirty,
              },
              styles.updateCollapsedBasketContainer
            )}
          >
            <Button
              id="basket-section-add-to-basket-btn"
              testId={`basket-section-${addToBasketLabel.testId}-btn`}
              leftIcon={<FaShoppingBasket />}
              onClick={handleAddToBasket}
              labelText={addToBasketLabel.label}
              variant="primary"
            />
          </div>

          <div className={styles.collapsedBasketContent}>
            <div className={styles.viewBasketCompleteBookingWrapper}>
              <div className={styles.viewBasketCompleteBooking_container}>
                {/* Basket Toggle & Payment Total */}
                <div className={styles.viewBasketAndTotal}>
                  <BasketPaymentSelector
                    basketValue={
                      showMonthlyPrice
                        ? `${format(basketData?.data?.calculated?.total?.monthlyPrice ?? 0, true)}`
                        : format(basketData?.data?.calculated?.total?.value ?? 0, true)
                    }
                    showPaymentSwitch={!!basketData?.data?.calculated?.total?.monthlyPrice}
                    onToggleBasket={toggleBasket}
                    onToggleSwitch={toggleMonthlyPrice}
                    isPrepayment={showMonthlyPrice}
                    labelPrePayment={t('basket:prePaymentPlanlabel')}
                    labelPrePaymentAbbrv={t('basket:prePaymentPerMonthAbbrv')}
                    labelTotal={t('common:words.total')}
                    fullWidth={!isOnMobile}
                    disabled={!hasEngineBasketItems}
                    testId="basket-section-view-basket-selector"
                  />
                </div>
                {/* Continue Desktop */}
                {shouldDisplayContinueButton && (
                  <div className={styles.collapsedBasketContainer_footer}>
                    <div className={styles.collapsedBasketContainer_continueWrapper}>
                      {!isOnMobile && <Button {...continueButtonProps} wide />}
                    </div>
                  </div>
                )}
              </div>
              {/* Continue Mobile */}
              {isOnMobile && (
                <div className={styles.collapsedBasketContainer_continueWrapper}>
                  <Button {...continueButtonProps} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BasketSection;
