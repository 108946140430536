import { QK_SHOW_3_DOTS_LOADING, QK_SHOW_KABOODLE_LOADING } from '@src/api/queryKeys';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

import KaboodleLoadingSVG from '../LoadingSVG/LoadingSVG';
import { LoadingDots } from '@src/components/LoadingDots/LoadingDots';
import { includesMutationOrQueryKey } from '@src/lib/includesMutationOrQueryKey';
import { useLoadingStore } from '@src/store/useLoadingStore';

const HandleAppLoading = () => {
  const { isAppInitializing, isStripe3DSLoading } = useLoadingStore();

  const showKabLoadingFetching = useIsFetching({
    predicate: (query) => includesMutationOrQueryKey(query, QK_SHOW_KABOODLE_LOADING),
  });
  const showKabLoadingMutating = useIsMutating({
    predicate: (mutation) => includesMutationOrQueryKey(mutation, QK_SHOW_KABOODLE_LOADING),
  });
  const showKabLoading = showKabLoadingFetching || showKabLoadingMutating;

  const show3DotsLoadingSpinnerFetching = useIsFetching({
    predicate: (query) => includesMutationOrQueryKey(query, QK_SHOW_3_DOTS_LOADING),
  });
  const show3DotsLoadingSpinnerMutating = useIsMutating({
    predicate: (mutation) => includesMutationOrQueryKey(mutation, QK_SHOW_3_DOTS_LOADING),
  });
  const show3DotsLoadingSpinner = show3DotsLoadingSpinnerFetching || show3DotsLoadingSpinnerMutating;

  if (isAppInitializing || showKabLoading) {
    return <KaboodleLoadingSVG />;
  }

  if (Boolean(show3DotsLoadingSpinner + show3DotsLoadingSpinnerMutating) || isStripe3DSLoading) {
    return <LoadingDots />;
  }
};

export { HandleAppLoading };
