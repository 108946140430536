import { useEffect, useRef } from 'react';
import { useBasketExpiryTimerStore } from '@src/store/useBasketExpiryTimerStore';

function useBasketExpiryTimer(onTimerExpired?: () => void) {
  const timeRemaining = useBasketExpiryTimerStore((state) => state.timeRemaining);
  const setTimeRemaining = useBasketExpiryTimerStore((state) => state.setTimeRemaining);

  const timeoutRef = useRef<number | null>(null);
  const clearTimeout = () => window.clearTimeout(timeoutRef.current!);

  useEffect(() => {
    if (timeRemaining == null) return;
    if (timeRemaining === 0) {
      onTimerExpired?.();
      return clearTimeout;
    }

    timeoutRef.current = window.setTimeout(() => {
      setTimeRemaining(timeRemaining - 1);
    }, 1000);
    return clearTimeout;
  }, [timeRemaining, onTimerExpired, setTimeRemaining]);

  return timeRemaining ?? 0;
}

export { useBasketExpiryTimer };
