import { AiOutlineLoading } from 'react-icons/ai';
import DeleteIcon from '../../../styles/svg/delete-icon.svg?react';
import styles from './basketContent.module.scss';

interface RemoveButtonProps {
  disabled: boolean;
  isRemoving: boolean;
  onRemove: () => void;
}

export const RemoveButton = ({ disabled = false, isRemoving = false, onRemove }: RemoveButtonProps) => (
  <button
    data-testid="basket-delete-button"
    disabled={disabled}
    className={styles.basketItem__deleteButton}
    onClick={onRemove}
  >
    {isRemoving ? (
      <AiOutlineLoading data-testid="basket-delete-icon-spinner" className={styles.basketItem__spinnerIcon} />
    ) : (
      <DeleteIcon data-testid="basket-delete-icon-trash" />
    )}
  </button>
);
