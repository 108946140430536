import { BasketItemWrapper } from '../BasketItemWrapper';
import { BasketTicketItem } from '@components/Basket/BasketContent/BasketContent.types';
import { EngineTitle } from '../EngineTitle';
import { Typography } from '@kaboodle-solutions/design-library';
import { calculateLineTotalByCount } from '@components/Basket/BasketContent/BasketContent.helper';
import { getPropertiesFromCartItem } from '@components/Basket/BasketContent/BasketCartItem.helper';
import styles from '../basketContent.module.scss';
import useCurrencyFormatter from '@src/hooks/useCurrencyFormatter';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface TicketCartItemProps {
  basketMutationStatus?: string;
  engineTitle: string;
  index: number;
  isRemoving?: boolean;
  item: BasketTicketItem;
  mode: 'basket' | 'checkout' | 'completed' | undefined;
  onEngineNameClick: () => void;
  removeItem?: (item: BasketTicketItem) => Promise<void>;
}

export const TicketCartItem = ({
  basketMutationStatus,
  engineTitle,
  index,
  isRemoving = false,
  item,
  mode,
  onEngineNameClick,
  removeItem,
}: TicketCartItemProps) => {
  const { t } = useTranslation();
  const { format } = useCurrencyFormatter();

  const { count, holders, name, price, irremovable } = getPropertiesFromCartItem(item);

  const itemTotal = useMemo(
    () => (price?.value ? format(calculateLineTotalByCount({ count, price: price.value })) : null),
    [format, count, price]
  );
  const bookingFeeTotal = useMemo(
    () => (price.bookingFee ? format(calculateLineTotalByCount({ count, price: price.bookingFee }), true) : null),
    [format, count, price]
  );

  const handleRemoveItem = () => removeItem?.(item);

  const showBookingFee = Boolean(bookingFeeTotal);
  const showRemoveButton = mode === 'basket' && !irremovable;
  const isDisabledRemoveButton = basketMutationStatus === 'pending';

  return (
    <>
      <EngineTitle title={engineTitle} index={index} mode={mode} onTitleClick={onEngineNameClick} />
      <BasketItemWrapper
        showRemoveButton={showRemoveButton}
        isDisabledRemoveButton={isDisabledRemoveButton}
        isRemoving={isRemoving}
        onRemove={handleRemoveItem}
      >
        {/* Display ticket name and price */}
        <div className={styles.basketItem_rowDesc}>
          <Typography tag="span" customClass={styles.basketItem_name}>
            <Typography tag="span" isBold customClass={styles.basketItem_Count}>
              {count}x
            </Typography>
            {` ${name}`}
          </Typography>
          <Typography tag="span" customClass={styles.basketItem_price}>
            {itemTotal}
          </Typography>
        </div>
        {/*  Display booking fee if higher then 0 */}
        {showBookingFee && (
          <div className={styles.basketItem_rowBookingFee}>
            <Typography tag="span" customClass={styles.basketItem_bookingFee}>
              {t('common:bookingFee')}
            </Typography>
            <Typography tag="span" customClass={styles.basketItem_bookingFeePrice}>
              {bookingFeeTotal}
            </Typography>
          </div>
        )}
        {/* Display ticket holder name & ticket count */}
        {!!holders &&
          holders
            .filter(({ firstName, lastName }) => firstName?.trim() !== '' || lastName?.trim() !== '')
            .map(({ firstName, lastName }, idx) => (
              <div key={`${idx}`} className={styles.basketItem_rowCustomerName}>
                <Typography tag="span" type="subtext">
                  <span className={styles.basketItem_Count}>1x</span>
                </Typography>
                <Typography tag="span" type="subtext" customClass={styles.basketItem_Customer}>
                  {` ${firstName} ${lastName} `}
                </Typography>
              </div>
            ))}{' '}
      </BasketItemWrapper>
    </>
  );
};
