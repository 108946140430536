import { getEnvironmentFromEnvironment } from './getEnvironmentFromEnvironment';

const STAGING_CDN = 'https://staging-css.kaboodle.com';
const PRODUCTION_CDN = 'https://css.kaboodle.com';

export const getCdnUrlFromEnvironment = () => {
  const environment = getEnvironmentFromEnvironment();

  switch (environment) {
    case 'dev':
    case 'staging':
      return STAGING_CDN;
    case 'testing':
    case 'production':
      return PRODUCTION_CDN;
    default:
      return STAGING_CDN;
  }
};
