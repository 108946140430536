import { create } from 'zustand';

interface LoadingState {
  isAppInitializing: boolean;
  setIsAppInitializing: (newState: boolean) => void;

  isStripe3DSLoading: boolean;
  setIsStripe3DSLoading: (newState: boolean) => void;
}

export const useLoadingStore = create<LoadingState>((set) => ({
  isAppInitializing: true,
  setIsAppInitializing: (newState: boolean) => set({ isAppInitializing: newState }),

  isStripe3DSLoading: false,
  setIsStripe3DSLoading: (newState: boolean) => set({ isStripe3DSLoading: newState }),
}));
