import { createJSONStorage, persist } from 'zustand/middleware';

import { create } from 'zustand';
import customStorageDefinition from '../customStorageDefinition';
import { SelectedEngineFilterParams } from '@src/constants';

interface SelectedEngineFilterState {
  _hasSelectedEngineFilterStoreHydrated: boolean;
  setHasHydrated: (hasHydrated: boolean) => void;

  [SelectedEngineFilterParams.EVENT_ID]: string;
  [SelectedEngineFilterParams.ACCOMMODATION_GROUP_ID]: string;
  [SelectedEngineFilterParams.ACCOMMODATION_IDS]: string | undefined;
  [SelectedEngineFilterParams.EXTRA_TYPE_ID]: string;
  [SelectedEngineFilterParams.TRANSPORT_TYPE]: string;

  setSelectedEngineFilterStore: (
    stateToUpdate: `${SelectedEngineFilterParams}`,
    newStateValue: string | undefined
  ) => void;
  clearSelectedEngineFilterStore: () => void;
}

export const defaultValues = {
  [SelectedEngineFilterParams.EVENT_ID]: '',
  [SelectedEngineFilterParams.ACCOMMODATION_GROUP_ID]: '',
  [SelectedEngineFilterParams.ACCOMMODATION_IDS]: undefined,
  [SelectedEngineFilterParams.EXTRA_TYPE_ID]: '',
  [SelectedEngineFilterParams.TRANSPORT_TYPE]: '',
};

const useSelectedEngineFilterStore = create<SelectedEngineFilterState>()(
  persist(
    (set) => ({
      _hasSelectedEngineFilterStoreHydrated: false,
      setHasHydrated: (hasHydrated) => {
        set({
          _hasSelectedEngineFilterStoreHydrated: hasHydrated,
        });
      },
      ...defaultValues,
      clearSelectedEngineFilterStore: () => set({ ...defaultValues }),
      setSelectedEngineFilterStore: (stateToUpdate, newStateValue) => set({ [stateToUpdate]: newStateValue }),
    }),
    {
      name: 'selected-engine-filter-storage',
      storage: createJSONStorage(() => customStorageDefinition),
      onRehydrateStorage: () => (state) => {
        state?.setHasHydrated(true);
      },
    }
  )
);

export default useSelectedEngineFilterStore;
