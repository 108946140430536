import {
  type BasketTicket,
  type BaseBasket,
  type BasketExtra,
  type BasketAccommodation,
} from '@src/api/useBasket/useBasket.types';
import { type BasketTransport, type UpdateBasketRequestBody } from '@src/interfaces/engineBasketPutRequest';
import { toastNotification } from '@src/lib/toast/toast';
import { type TFunction } from 'i18next';
import { getMutationSuccessStatus } from './basketMutationSuccessStatus.helper';

const engines = ['tickets', 'accommodations', 'extras', 'transports'] as const;
type RemovedItemType = BasketTransport | BasketTicket | BasketExtra | BasketAccommodation;

const displayBasketSuccess = (
  previousData: BaseBasket | undefined | null,
  newData: BaseBasket | undefined | null,
  mutationPayload: Partial<UpdateBasketRequestBody>,
  t: TFunction<'basketToastNotifications', undefined, 'basketToastNotifications'>
) => {
  if (newData && newData.errors?.length !== 0 && mutationPayload) {
    engines.forEach((engine) => {
      if (engine in mutationPayload) {
        const mutationPayloadResponse = mutationPayload[engine];
        const newBasketEngineResult = newData[engine] ?? [];
        const oldBasketEngineResult = previousData ? previousData[engine] ?? [] : [];

        if (
          Array.isArray(mutationPayloadResponse) &&
          Array.isArray(newBasketEngineResult) &&
          Array.isArray(oldBasketEngineResult)
        ) {
          const mutationStatus = getMutationSuccessStatus(
            mutationPayloadResponse,
            newBasketEngineResult,
            oldBasketEngineResult
          );

          if (mutationStatus.status === 'Unhandled') return;
          const { autoClose, status, testId, toastMessageTReference, toastVariant } = mutationStatus;

          if (status !== 'ItemRemoved') {
            toastNotification({
              testId: `${engine}-${testId}`,
              toastId: `${t(engine)}-${t(`${toastMessageTReference}`)}`,
              content: `${t(engine)} ${t(`${toastMessageTReference}`)}`,
              variant: toastVariant,
              autoClose,
            });
          }

          if (status === 'ItemRemoved') {
            const quantityOfItemsRemoved = oldBasketEngineResult.length - newBasketEngineResult.length;
            const removedItem = oldBasketEngineResult[0];

            const possibleItemNameKeys = ['name', 'accommodationName', 'routeName', 'extraName'] as const;
            const removeItemKey = possibleItemNameKeys.find((key) => key in removedItem);
            const removeItemName = removedItem[removeItemKey as keyof RemovedItemType] ?? 'Item';

            toastNotification({
              testId: `${engine}-${testId}`,
              toastId: `${quantityOfItemsRemoved}x-${removeItemName}-${t(`${toastMessageTReference}`)}`,
              content: `${quantityOfItemsRemoved}x ${removeItemName} ${t(`${toastMessageTReference}`)}`,
              variant: toastVariant,
              autoClose,
            });
          }
        }
      }
    });
  }
};

export default displayBasketSuccess;
