import { Typography } from '@kaboodle-solutions/design-library';
import styles from './basketContent.module.scss';

interface EngineTitleProps {
  index: number;
  mode: 'basket' | 'checkout' | 'completed' | undefined;
  title: string;
  onTitleClick: () => void;
}

export const EngineTitle = ({ index, mode, title, onTitleClick }: EngineTitleProps) => {
  const showBasketTitle = index === 0 && mode === 'basket';
  const showCheckoutTitle = index === 0 && mode === 'checkout';

  return (
    <>
      {showBasketTitle && (
        <button
          id="basket-cart-engine-name-link"
          className={styles.basketItemEngineNameLink}
          onClick={onTitleClick}
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onTitleClick();
            }
          }}
        >
          <Typography tag="span" customClass={styles.basketItemEngineNameLinkText}>
            {title}
          </Typography>
        </button>
      )}
      {showCheckoutTitle && (
        <Typography tag="span" isBold customClass={styles.basketItemEngineName}>
          {title}
        </Typography>
      )}
    </>
  );
};
