import { BasketTransport, KapiTransportLeg } from '@src/interfaces/engineBasketPutRequest'; // or from '@src/interfaces/transport' if you created a new file
import { useBasketMutation, useBasketQuery } from '@src/api/useBasket';

import { TransportTypeOption } from '@src/interfaces/transport';
import { useCallback } from 'react';

const sanitizeTransport = (transport: BasketTransport): BasketTransport => {
  return {
    routeId: transport.routeId,
    legs: transport.legs.map((leg: KapiTransportLeg) => ({
      type: leg.type,
      stopId: leg.stopId,
      flightNumber: null,
    })),
  };
};

export const useTransportMutation = () => {
  const { data: basketData } = useBasketQuery();
  const { mutateAsync, isPending } = useBasketMutation({
    patch: !!basketData?.data,
  });

  const getPayLoadFromBasket = useCallback(
    (type?: TransportTypeOption) => {
      if (!type) return (basketData?.data?.transports || []).map(sanitizeTransport);
      return (basketData?.data?.transports.filter((x) => x.type !== type) || []).map(sanitizeTransport);
    },
    [basketData?.data?.transports]
  );

  const mutateBasketWithTransport = useCallback(
    async (transport: BasketTransport, customers: number, setBasketTransport: (transport: BasketTransport) => void) => {
      const { type } = transport;
      const existingTransports = getPayLoadFromBasket(type);
      const newTransports = Array(customers).fill(transport).map(sanitizeTransport);

      const payloadToSend = [...existingTransports, ...newTransports];
      const mutationPayload: { transports: BasketTransport[] } = { transports: payloadToSend };

      await mutateAsync(mutationPayload, {
        onSuccess: ({ data }) => {
          const addedTransport = data?.transports?.find((t) => t.type === type);
          if (addedTransport) {
            setBasketTransport(addedTransport);
          }
        },
      });
    },
    [mutateAsync, getPayLoadFromBasket]
  );

  return {
    mutateBasketWithTransport,
    isPending,
  };
};
