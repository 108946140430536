import { BasketAccommodationItem } from '@components/Basket/BasketContent/BasketContent.types';
import { BasketItemWrapper } from '../BasketItemWrapper';
import { EngineTitle } from '../EngineTitle';
import { Typography } from '@kaboodle-solutions/design-library';
import { calculateLineTotalByCount } from '@components/Basket/BasketContent/BasketContent.helper';
import classNames from 'classnames';
import { getPropertiesFromCartItem } from '@components/Basket/BasketContent/BasketCartItem.helper';
import styles from '../basketContent.module.scss';
import useCurrencyFormatter from '@src/hooks/useCurrencyFormatter';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface AccommodationCartItemProps {
  basketMutationStatus?: string;
  engineTitle: string;
  index: number;
  isRemoving?: boolean;
  item: BasketAccommodationItem;
  mode: 'basket' | 'checkout' | 'completed' | undefined;
  onEngineNameClick: () => void;
  removeItem?: (item: BasketAccommodationItem) => Promise<void>;
}

export const AccommodationCartItem = ({
  basketMutationStatus,
  engineTitle,
  index,
  isRemoving = false,
  item,
  mode,
  onEngineNameClick,
  removeItem,
}: AccommodationCartItemProps) => {
  const { t } = useTranslation();
  const { format } = useCurrencyFormatter();

  const { count, name, price, irremovable, showAccommName } = getPropertiesFromCartItem(item);

  const itemTotal = useMemo(
    () => (price?.value ? format(calculateLineTotalByCount({ count, price: price.value })) : null),
    [format, count, price]
  );
  const bookingFeeTotal = useMemo(
    () => (price.bookingFee ? format(calculateLineTotalByCount({ count, price: price.bookingFee }), true) : null),
    [format, count, price]
  );

  const handleRemoveItem = () => removeItem?.(item);

  const showBookingFee = Boolean(bookingFeeTotal);
  const showRemoveButton = mode === 'basket' && !irremovable;
  const isDisabledRemoveButton = basketMutationStatus === 'pending';

  const accommHasExtras = typeof item === 'object' && 'ruleId' in item && item.extras.length > 0;

  return (
    <>
      <EngineTitle title={engineTitle} index={index} mode={mode} onTitleClick={onEngineNameClick} />
      {showAccommName && (
        <Typography tag="span" isBold customClass={styles.packageTitle}>
          {'accommodationName' in item ? item?.accommodationName : ''}
        </Typography>
      )}
      <BasketItemWrapper
        showRemoveButton={showRemoveButton}
        isDisabledRemoveButton={isDisabledRemoveButton}
        isRemoving={isRemoving}
        onRemove={handleRemoveItem}
      >
        {/* Display ticket name and price */}
        <div className={styles.basketItem_rowDesc}>
          <Typography tag="span" customClass={styles.basketItem_name}>
            <Typography tag="span" isBold customClass={styles.basketItem_Count}>
              {count}x
            </Typography>
            {` ${name}`}
          </Typography>
          <Typography tag="span" customClass={styles.basketItem_price}>
            {itemTotal}
          </Typography>
        </div>

        {/*  Display booking fee if higher then 0 */}
        {showBookingFee && (
          <div className={styles.basketItem_rowBookingFee}>
            <Typography tag="span" customClass={styles.basketItem_bookingFee}>
              {t('common:bookingFee')}
            </Typography>
            <Typography tag="span" customClass={styles.basketItem_bookingFeePrice}>
              {bookingFeeTotal}
            </Typography>
          </div>
        )}
      </BasketItemWrapper>
      {accommHasExtras && (
        <div className={styles.basketItem__extrasWrapper}>
          {
            <Typography tag="span" isBold customClass={styles.basketExtrasName}>
              {t('accommodationEngine:titleWithExtras')}
            </Typography>
          }
          {item.extras.map((extra, index) => (
            <div key={`${extra.optionId}-${index}`} className={styles.basketItem}>
              <div className={styles.basketItem_rowDesc}>
                <Typography tag="span" customClass={styles.basketItem_name}>
                  <Typography tag="span" isBold customClass={styles.basketItem_Count}>
                    {extra.count}x
                  </Typography>{' '}
                  {extra.optionName}
                </Typography>
                <Typography tag="span" customClass={styles.basketItem_price}>
                  {format(extra.price?.value * extra.count)}
                </Typography>
              </div>
              {extra.price?.bookingFee > 0 && (
                <div className={styles.basketItem_rowBookingFee}>
                  <Typography tag="span" customClass={styles.basketItem_bookingFee}>
                    {t('common:bookingFee')}
                  </Typography>
                  <Typography tag="span" customClass={styles.basketItem_bookingFeePrice}>
                    {format(extra.price?.bookingFee * extra.count, true) || 0}
                  </Typography>
                </div>
              )}
              {!!extra.textInput && extra?.textInput?.length > 0 && (
                <div className={styles.basketItem_rowBookingFee}>
                  <Typography
                    tag="span"
                    customClass={classNames(styles.basketItem_bookingFee, styles.basketItem_dataCapture)}
                  >
                    {extra.textInput}
                  </Typography>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
