import { EngineContext } from '@src/interfaces/engineData';
import { create } from 'zustand';

const continueButtonVariant: 'primary' | 'secondary' = 'primary';

export const DEFAULT_BASKET_STATE = {
  showBasket: false,
  getBasketRequestState: undefined,
  isTicketStateDirty: undefined,
  resetTicketState: undefined,
  continueButtonVariant,
  showCompleteBookingLabel: false,
  showMonthlyPrice: false,
};

export type BasketStoreState = {
  mutateBasketStatus: 'error' | 'idle' | 'pending' | 'success';
  isTicketStateDirty?: boolean;
  showMonthlyPrice: boolean;
} & EngineContext;

type BasketStoreActions = {
  setBasketStore: (newState: Partial<BasketStoreState>) => void;
  setMutateBasketStatus: (newStatus: BasketStoreState['mutateBasketStatus']) => void;
  toggleMonthlyPrice: () => void;
};

type BasketStore = BasketStoreState & BasketStoreActions;

export const useBasketStore = create<BasketStore>()((set) => ({
  ...DEFAULT_BASKET_STATE,
  mutateBasketStatus: 'idle',
  setBasketStore: (newState) => {
    set(newState);
  },
  setMutateBasketStatus: (newStatus) => set({ mutateBasketStatus: newStatus }),
  toggleMonthlyPrice: () => set((state) => ({ showMonthlyPrice: !state.showMonthlyPrice })),
}));
