import { AxiosError, isAxiosError, type AxiosResponse } from 'axios';
import { captureException, withScope } from '@sentry/react';
import { ReactQueryError } from '@src/lib/basketNotificationHandling/ReactQueryError';

type SentryReportingOptions = {
  customErrorHandler?: (AxiosError: AxiosError) => void;
  skipErrorResponsesReporting?: number[];
  skipAllReporting?: boolean;
  additionalContext?: Record<string, unknown>;
};

export function axiosRequestWithSentryReporting<Args extends unknown[], ReturnType>(
  apiCall: (...args: Args) => Promise<AxiosResponse<ReturnType>>,
  options: SentryReportingOptions = {}
) {
  return async (...args: Args) => {
    return await apiCall(...args).catch((error) => {
      if (isAxiosError(error)) {
        if (options.customErrorHandler) {
          options.customErrorHandler(error);
        }

        if (
          error.response?.status &&
          !options.skipErrorResponsesReporting?.includes(error.response.status) &&
          !options.skipAllReporting
        ) {
          withScope((scope) => {
            scope.setExtra('endpoint', error.config?.url);
            scope.setExtra('method', error.config?.method);
            if (options.additionalContext) {
              Object.entries(options.additionalContext).forEach(([key, value]) => {
                scope.setExtra(key, value);
              });
            }
            captureException(error);
          });
        }

        // We need to throw an error otherwise react-query wont catch it in case of api call failure
        throw new ReactQueryError(error, true);
      } else {
        captureException(error);
        throw error;
      }
    });
  };
}
