import { ENGINE_NAV_DATA } from '@src/constants';
import { handleCheckCurrentStep } from './useNavigationController.helper';
import { useBasketQuery } from '@src/api/useBasket';
import { useEffect } from 'react';
import { useEngineNavigationStore } from '@src/store/useEngineNavigationStore';
import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { useLocation } from 'react-router-dom';
import { useNavigateWithQueryParams } from '@src/hooks/useNavigateWithQueryParams/useNavigateWithQueryParams';

export const useNavigationController = () => {
  const { steps, generateSteps, setNavigateFunction, setLocation } = useEngineNavigationStore();
  const { data: basketData, isFetched: isBasketFetched, isSuccess: isBasketSuccess } = useBasketQuery();
  const { engines } = useInitResponseStore();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const location = useLocation();

  // Regenerate steps on every new basket data
  useEffect(() => {
    generateSteps(basketData?.data, engines, isBasketFetched);
  }, [basketData?.data, generateSteps, engines, isBasketFetched]);

  // Check if use should be on this step on every new basket update or whenever location changes
  useEffect(() => {
    if (steps && navigateWithQueryParams && isBasketFetched && isBasketSuccess && location.pathname) {
      handleCheckCurrentStep(steps, navigateWithQueryParams);
    }
  }, [steps, navigateWithQueryParams, isBasketFetched, isBasketSuccess, location.pathname]);

  // If the basket has a booking ref, send the user off to the booking success page except if already on a success page
  useEffect(() => {
    if (basketData?.data?.bookingRef && location.pathname !== ENGINE_NAV_DATA['CHECKOUT_STATUS'].route) {
      navigateWithQueryParams(ENGINE_NAV_DATA['BOOKING_STATUS'].route);
    }
  }, [basketData?.data?.bookingRef, navigateWithQueryParams, location.pathname]);

  // Syncs location from useLocation with storage
  useEffect(() => {
    if (location) {
      setLocation(location);
    }
  }, [location, setLocation]);

  // Configures default navigate function
  useEffect(() => {
    setNavigateFunction(navigateWithQueryParams);
  }, [setNavigateFunction, navigateWithQueryParams]);
};
